<template>
    <div>
        <v-card>
            <v-container>
                <form ref="usuarioForm">
                    <!-- <validation-observer ref="observer"> -->
                    <v-row>
                        <v-col sm="12" md="12" lg="12" class="ma-auto text-center">
                            <v-icon size="150">mdi-playlist-plus</v-icon>
                            <v-card-text
                                class="
                                        primary--text
                                        font-weight-black
                                        display-1
                                    "
                            >
                                Novo Apresentante
                            </v-card-text>
                        </v-col>
                        <v-col sm="12" md="12" lg="12">
                            <v-form ref="form" v-model="valid">
                                <v-row class="my-6">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Número do CNPJ
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="cnpj"
                                            label="Exemplo: 00.000.000/0000-00"
                                            :rules="[
                                                rules.required,
                                                rules.validarCNPJ
                                            ]"
                                            v-mask="'XX.XXX.XXX/XXXX-##'"
                                            v-model="apresentante.nu_cnpj"
                                            required
                                            data-cy="apresentanteCnpj"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Código do Apresentante
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="cd_apresentante"
                                            label="Ex.:AYS"
                                            maxlength="3"
                                            v-model.trim="
                                                apresentante.cd_apresentante
                                            "
                                            :rules="codigoRulesConfirmation"
                                            required
                                            :onkeypress="
                                                permitirLetrasNumeros(true)
                                            "
                                            data-cy="apresentanteCodigo"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Nome de Exibição
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="7">
                                        <v-text-field
                                            solo
                                            label="Nome de exibição"
                                            prepend-icon="mdi-information-outline"
                                            name="nome_exibicao"
                                            type="text"
                                            v-model="apresentante.nome_exibicao"
                                            :rules="nameRulesConfirmation"
                                            data-cy="apresentanteNome"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Razão Social
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="nm_razao_social"
                                            label="Razão Social"
                                            v-model="
                                                apresentante.nm_razao_social
                                            "
                                            :rules="nameRulesConfirmation"
                                            required
                                            data-cy="apresentanteRazao"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Data de liberação dos estados
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-menu
                                            ref="menu"
                                            v-model="menuDate"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="formatDate(apresentante.dt_liberacao_estados)"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                locale="pt-br"
                                                v-model="apresentante.dt_liberacao_estados"
                                                :active-picker.sync="activePicker"
                                                @input="menuDate = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Usuário CRA
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="usuarioCra"
                                            label="Usuário CRA"
                                            v-model="apresentante.cra_user"
                                            :rules="nameRulesConfirmation"
                                            required
                                            data-cy="apresentanteCRA"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Senha CRA
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="craPassword"
                                            label="Senha Cra"
                                            v-model="apresentante.cra_passwd"
                                            :rules="nameRulesConfirmation"
                                            required
                                            data-cy="apresentanteSenhaCRA"
                                        ></v-text-field>
                                    </v-col>
                                    <!-- <v-col sm="6" md="4">
                                                <v-card-text
                                                    class="font-weight-light"
                                                >
                                                    <v-icon
                                                        >mdi-chevron-right</v-icon
                                                    >Confirmar Senha CRA
                                                </v-card-text>
                                        </v-col>
                                        <v-col sm="7" md="7">
                                                <v-text-field
                                                    solo
                                                    prepend-icon="mdi-information-outline"
                                                    name="craPasswordConfirmation"
                                                    label="Confirmar senha CRA"
                                                    v-model="passwordConfirmation"
                                                    :rules="
                                                        nameRulesConfirmation
                                                    "
                                                    required
                                                ></v-text-field>
                                        </v-col> -->
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Link de contato
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-text-field
                                            prepend-icon="mdi-information-outline"
                                            name="linkContato"
                                            v-model="apresentante.link_contato"
                                            solo
                                            label="Link de contato"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >E-mail Apresentante
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-text-field
                                            solo
                                            label="E-mail de contato"
                                            prepend-icon="mdi-information-outline"
                                            name="emailApresentante"
                                            v-model="
                                                apresentante.email_apresentante
                                            "
                                            data-cy="apresentanteEmail"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Ambiente do Apresentante (Partner
                                            ID)
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-select
                                            :items="partners"
                                            solo
                                            item-text="fullname"
                                            item-value="id"
                                            name="partner"
                                            prepend-icon="mdi-information-outline"
                                            label="Partner ID"
                                            required
                                            v-model="apresentante.partner_id"
                                            data-cy="apresentanteAmbiente"
                                        ></v-select>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Tipo do Apresentante
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-select
                                            :items="listaTiposApresentante"
                                            solo
                                            item-text="cd_tipo_apresentante"
                                            item-value="id_tipo_apresentante"
                                            name="tipo"
                                            prepend-icon="mdi-information-outline"
                                            label="Tipo Apresentante"
                                            required
                                            v-model="
                                                apresentante.id_tipo_apresentante
                                            "
                                            data-cy="apresentanteTipo"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        sm="6"
                                        md="11"
                                        class="mx-auto"
                                        style="display: flex"
                                    >
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-row>
                                                    <v-switch
                                                        outlined
                                                        color="success"
                                                        v-model="
                                                            apresentante.st_ativo
                                                        "
                                                        label="Está ativo?"
                                                        required
                                                        name="is_active"
                                                        data-cy="apresentanteStatus"
                                                    />
                                                    <v-icon
                                                        class="ml-3"
                                                        color="grey lighten-2"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        help
                                                    </v-icon>
                                                </v-row>
                                            </template>
                                            <span
                                                >Selecione essa opção para
                                                definir se o usuário está ativo.
                                                Caso o usuário estiver inativo,
                                                não irá conseguir acessar o
                                                sistema.
                                            </span>
                                        </v-tooltip>

                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-row
                                                    style="justify-content: center;"
                                                >
                                                    <v-switch
                                                        outlined
                                                        color="success"
                                                        v-model="
                                                            apresentante.cra_nacional
                                                        "
                                                        label="Apresentante CRA Nacional"
                                                        required
                                                        name="cra_nacional"
                                                        data-cy="craNacional"
                                                    ></v-switch>
                                                    <v-icon
                                                        class="ml-3"
                                                        color="grey lighten-2"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        help
                                                    </v-icon>
                                                </v-row>
                                            </template>
                                            <span
                                                >Selecione essa opção para
                                                definir se o apresentante é
                                                pertencente ao CRA Nacional.
                                            </span>
                                        </v-tooltip>

                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-row
                                                    style="justify-content: center;"
                                                >
                                                    <v-switch
                                                        outlined
                                                        color="success"
                                                        v-model="
                                                            apresentante.st_ativo_negociacao
                                                        "
                                                        label="Habilitar Negociação?"
                                                        required
                                                        name="st_ativo_negociacao"
                                                        data-cy="stAtivoNegociacao"
                                                    ></v-switch>
                                                    <v-icon
                                                        class="ml-3"
                                                        color="grey lighten-2"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        help
                                                    </v-icon>
                                                </v-row>
                                            </template>
                                            <span
                                                >Selecione essa opção para habilitar o processo de negociação para o apresentante.
                                            </span>
                                        </v-tooltip>

                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >URL endpoint
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="url_endpoint"
                                            label="Exemplo: https://endereco.com.br/exemplo"
                                            v-model="
                                                apresentante.callback_url_endpoint
                                            "
                                            data-cy="urlEndpoint"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Callback Certificado
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="certificado_apresentante"
                                            label="Informe o certificado do Apresentante"
                                            v-model="
                                                apresentante.callback_certificate
                                            "
                                            data-cy="certificado_apresentante"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Chave Callback
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="key_apresentante"
                                            label="Informe a chave do apresentante"
                                            v-model="apresentante.callback_key"
                                            data-cy="key_apresentante"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Parametros callback JWT
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-textarea
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="callback_jwt_request_params"
                                            label="Informe o usuário de autenticação callback"
                                            v-model="
                                                apresentante.callback_jwt_request_params
                                            "
                                            data-cy="callback_jwt_request_params"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <CardConfigMensageria :cd_apresentante="''" @update:lembrete="setLembrete"/>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                    <!-- </validation-observer> -->
                </form>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import ApresentanteService from '@/services/apresentanteService';
import CardConfigMensageria from './organisms/CardConfigMensageria.vue';
import Vue from 'vue';
import { OnRules } from 'vuetify-js-utils';
import moment from 'moment';
import { validarCNPJ }from '../helpers/Utilitarios';

export default {
    mixins: [OnRules],
    name: 'DetalheApresentante',
    components: {
        CardConfigMensageria
    },
    props: {
        apresentante: {
            type: Object,
            required: false
        },
        listaTiposApresentante: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            show1: false,
            menuDate: false,
            activePicker: null,
            errorMessages: null,
            rules: {
                required: value => !!value || 'Campo obrigatório',
                validarCNPJ: value => this.validarCNPJ(value) || 'CNPJ inválido'
            },
            apresentantes: {
                id_user: '',
                ds_login: '',
                st_ativo: false,
                is_admin: false,
                name: '',
                password: '',
                email: ''
            },
            items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
            id_user: '',
            partners: [],
            ds_login: '',
            st_ativo: false,
            enabled: false,
            password: '',
            email: '',
            valid: null,
            createPassword: '',
            emailConfirmation: '',
            passwordRules: [
                v => !!v || 'Senha é requerida',
                v =>
                    (v && v.length > 2) ||
                    'A senha deve ter 2 ou mais caracteres'
            ],
            passwordRulesConfirmation: [
                v => !!v || 'Senha é requerida',
                v =>
                    v == this.apresentante.password ||
                    'As senhas devem ser iguais'
            ],
            emailRulesConfirmation: [
                v => !!v || 'Email é requerido',
                v => v.length >= 2 || 'O e-mail deve ter 2 ou mais caracteres'
            ],
            nameRulesConfirmation: [
                v => !!v || 'Campo Obrigatório',
                v => (v && v.length >= 0) || 'Esse campo deve ser obrigatório'
            ],
            codigoRulesConfirmation: [
                v => !!v || 'Campo Obrigatório',
                v => (v && v.length === 3) || 'Esse campo deve ter 3 caracteres'
            ],
        };
    },
    methods: {
        validarCNPJ,
        setLembrete(dias) {
            this.apresentante.dias_para_envio = dias;
        },
        validarSalvar() {
            if (this.valid) {
                this.$emit('criarApresentante');
            } else {
                this.$refs.form.validate();
            }
        },
        formatDate(item) {
            let dataFormatada;
            if (item) {
                dataFormatada = moment(item).format('DD/MM/YYYY')
            } else {
                dataFormatada = '--';
            }
            return dataFormatada;
        },
        permitirLetrasNumeros(apenasMaiusculas = false) {
            const numeros = 'event.charCode >= 46 && event.charCode <= 57';
            const letrasMaiusculas =
                'event.charCode >= 65 && event.charCode <= 90';
            const letrasMinusculas =
                'event.charCode >= 97 && event.charCode <= 122';

            if (apenasMaiusculas) {
                return `return ${numeros} || ${letrasMaiusculas}`;
            }
            return `return ${numeros} || ${letrasMaiusculas} || ${letrasMinusculas}`;
        },
        listaPartners() {
            {
                return new Promise((resolve, reject) => {
                    try {
                        let apresentanteService = new ApresentanteService(
                            Vue.http,
                            this.$store
                        );
                        apresentanteService.listaPartners().then(
                            response => {
                                if (response.status == 200) {
                                    this.partners = response.body;
                                }
                            },
                            error => {
                                console.log(error);
                            }
                        );
                    } catch (e) {
                        this.mensagem = e;
                        console.log(e);
                        reject();
                    }
                });
            }
        },
        keyupListener(e) {
            if (!e) e = window.event;
            const keyCode = e.code || e.key;

            if (keyCode === 'Escape') {
                this.$emit('fecharModal');
            } else if (keyCode === 'Enter' || keyCode === 'NumpadEnter') {
                this.validarSalvar();
            }
        }
    },
    watch: {
        valid(val) {
            this.$emit('setIsInvalid', !val);
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.keyupListener);
    },
    mounted() {
        document.addEventListener('keyup', this.keyupListener);
        this.listaPartners();
        this.apresentante.id_tipo_apresentante = 6;
        // let cnpj = generateRandomCNPJ();
        // console.log(cnpj, validarCNPJ(cnpj));

    }
};
</script>

<style scoped></style>
