export default class SenhaService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }
    redefinirSenha() {
        return this._http.get('auth/alterar-senha/');
    }

    salvarSenha(password, email, id_user, cd_apresentante) {
        const data = {
            password: password,
            email: email,
            id_user: id_user,
            cd_apresentante: cd_apresentante
        };
        return this._http.post('usuario/salvar-nova-senha/', data);
    }
}
