export default class Apresentante {
    constructor() {
        this.nu_cnpj = '';
        this.cd_apresentante = '';
        this.nome_exibicao = null;
        this.nm_razao_social = '';
        this.cra_user = '';
        this.cra_passwd = '';
        this.st_ativo = null;
        this.link_contato = null;
        this.email_apresentante = null;
        this.partner_id = null;
        this.id_tipo_apresentante = null;
    }
}
